<template>
  <ion-page id="main-content">
    <Header page="home" />


    <!-- Page Content -->
    <ion-content :fullscreen="true">
      <div id="mobile-segment">
        <!-- Default Segment -->
        <ion-segment scrollable value="feed" v-model="view">
          <ion-segment-button value="feed">
            <ion-label>Feed</ion-label>
          </ion-segment-button>
          <ion-segment-button value="concerts">
            <ion-label>Concerts</ion-label>
          </ion-segment-button>
          <ion-segment-button value="artists">
            <ion-label>Artists</ion-label>
          </ion-segment-button>
          <ion-segment-button value="people">
            <ion-label>People</ion-label>
          </ion-segment-button>
          <ion-segment-button value="venues">
            <ion-label>Venues</ion-label>
          </ion-segment-button>
          <ion-segment-button value="pages">
            <ion-label>Pages</ion-label>
          </ion-segment-button>
        </ion-segment>
      </div>

      <div>
        <!-- Feed -->
        <div v-if="view == 'feed'">
          <PostFeed post-type="feed" :scene-id="sceneId" />
        </div>
        <!-- Feed -->
        <div v-if="view == 'concerts'">
          <PostFeed post-type="concerts" :scene-id="sceneId" />
        </div>
        <!-- Feed -->
        <div v-if="view == 'artists' && sceneId > 0">
          <ProfileAvatars profile-type="artist" :limit="0" view="full" :scene-id="sceneId" />
        </div>
        <!-- Feed -->
        <div v-if="view == 'people' && sceneId > 0">
          <ProfileAvatars profile-type="user" :limit="0" view="full" :scene-id="sceneId" />
        </div>
        <!-- Feed -->
        <div v-if="view == 'venues' && sceneId > 0">
          <ProfileAvatars profile-type="venue" :limit="0" view="full" :scene-id="sceneId" />
        </div>
        <!-- Feed -->
        <div v-if="view == 'pages' && sceneId > 0">
          <ProfileAvatars profile-type="business" :limit="0" view="full" :scene-id="sceneId" />
        </div>
      </div>
    </ion-content>
    
    <!-- put the tabs in ion-footer!!! -->
    <ion-footer v-if="device != 'web'">
      <Tabs page="home" />
    </ion-footer>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonFooter, IonSegment, IonSegmentButton, IonLabel } from '@ionic/vue';
import { defineComponent, ref, onMounted, computed } from 'vue';
import PostFeed from '../components/PostFeed.vue';
import doAuth from '../services/auth';
import Tabs from '../components/Tabs.vue';
import Header from '../components/Header.vue';
import ProfileAvatars from '../components/ProfileAvatars.vue';
import { useRouter } from 'vue-router';
import { isPlatform } from '@ionic/vue';
import { useStore } from "vuex";

export default defineComponent({
  name: 'Home',
  components: {
    IonPage, IonContent, IonFooter, Tabs, Header, IonSegment, IonSegmentButton, IonLabel, ProfileAvatars, PostFeed
  }, 
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const sceneId = ref(0);
    const view = ref('feed');
    const device = ref('web');


    onMounted(() => {
      if(authUser.value) {
        sceneId.value = authUser.value.scene_id
        store.commit('setSceneId', sceneId.value)
      }
      // App
      if(isPlatform('ios') === true || isPlatform('android') === true)  {
        device.value = 'mobile-app'
      } 
      // Web
      else {
        device.value = 'web'
      }
    })

    function updateRoute(r) {
      router.push(r)
    }



    return {
      updateRoute, router, view, device, sceneId
    }
  }

});
</script>

<style scoped>

  ion-segment {
    border-radius:0px!important;
  }


</style>
